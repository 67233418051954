/*
Width
Width In Percentage
Max Width
Min Width
Height
Height In Percentage
Max Height
Min Height
Gutter Gap
Rounded
Font
Badge
*/

/*Width*/
.w-0 {
  width: 0 !important;
}

.w-25p {
  width: 25px !important;
}

.w-30p {
  width: 30px !important;
}

.w-35p {
  width: 35px !important;
}

.w-40p {
  width: 40px !important;
}

.w-45p {
  width: 45px !important;
}

.w-50p {
  width: 50px !important;
}

.w-55p {
  width: 55px !important;
}

.w-60p {
  width: 60px !important;
}

.w-65p {
  width: 65px !important;
}

.w-70p {
  width: 70px !important;
}

.w-75p {
  width: 75px !important;
}

.w-80p {
  width: 80px !important;
}

.w-85p {
  width: 85px !important;
}

.w-90p {
  width: 90px !important;
}

.w-95p {
  width: 95px !important;
}

.w-100p {
  width: 100px !important;
}

.w-105p {
  width: 105px !important;
}

.w-110p {
  width: 110px !important;
}

.w-115p {
  width: 115px !important;
}

.w-120p {
  width: 120px !important;
}

.w-125p {
  width: 125px !important;
}

.w-130p {
  width: 130px !important;
}

.w-135p {
  width: 135px !important;
}

.w-140p {
  width: 140px !important;
}

.w-145p {
  width: 145px !important;
}

.w-150p {
  width: 150px !important;
}

.w-155p {
  width: 155px !important;
}

.w-160p {
  width: 160px !important;
}

.w-165p {
  width: 165px !important;
}

.w-170p {
  width: 170px !important;
}

.w-175p {
  width: 175px !important;
}

.w-180p {
  width: 180px !important;
}

.w-185p {
  width: 185px !important;
}

.w-190p {
  width: 190px !important;
}

.w-195p {
  width: 195px !important;
}

.w-200p {
  width: 200px !important;
}

.w-205p {
  width: 205px !important;
}

.w-210p {
  width: 210px !important;
}

.w-215p {
  width: 215px !important;
}

.w-220p {
  width: 220px !important;
}

.w-225p {
  width: 225px !important;
}

.w-230p {
  width: 230px !important;
}

.w-235p {
  width: 235px !important;
}

.w-240p {
  width: 240px !important;
}

.w-245p {
  width: 245px !important;
}

.w-250p {
  width: 250px !important;
}

.w-255p {
  width: 255px !important;
}

.w-260p {
  width: 260px !important;
}

.w-265p {
  width: 265px !important;
}

.w-270p {
  width: 270px !important;
}

.w-275p {
  width: 275px !important;
}

.w-280p {
  width: 280px !important;
}

.w-285p {
  width: 285px !important;
}

.w-290p {
  width: 290px !important;
}

.w-295p {
  width: 295px !important;
}

.w-300p {
  width: 300px !important;
}

.w-305p {
  width: 305px !important;
}

.w-310p {
  width: 310px !important;
}

.w-315p {
  width: 315px !important;
}

.w-320p {
  width: 320px !important;
}

.w-325p {
  width: 325px !important;
}

.w-330p {
  width: 330px !important;
}

.w-335p {
  width: 335px !important;
}

.w-340p {
  width: 340px !important;
}

.w-345p {
  width: 345px !important;
}

.w-350p {
  width: 350px !important;
}

.w-355p {
  width: 355px !important;
}

.w-360p {
  width: 360px !important;
}

.w-365p {
  width: 365px !important;
}

.w-370p {
  width: 370px !important;
}

.w-375p {
  width: 375px !important;
}

.w-380p {
  width: 380px !important;
}

.w-385p {
  width: 385px !important;
}

.w-390p {
  width: 390px !important;
}

.w-395p {
  width: 395px !important;
}

.w-400p {
  width: 400px !important;
}

.w-405p {
  width: 405px !important;
}

.w-410p {
  width: 410px !important;
}

.w-415p {
  width: 415px !important;
}

.w-420p {
  width: 420px !important;
}

.w-425p {
  width: 425px !important;
}

.w-430p {
  width: 430px !important;
}

.w-435p {
  width: 435px !important;
}

.w-440p {
  width: 440px !important;
}

.w-445p {
  width: 445px !important;
}

.w-450p {
  width: 450px !important;
}

.w-455p {
  width: 455px !important;
}

.w-460p {
  width: 460px !important;
}

.w-465p {
  width: 465px !important;
}

.w-470p {
  width: 470px !important;
}

.w-475p {
  width: 475px !important;
}

.w-480p {
  width: 480px !important;
}

.w-485p {
  width: 485px !important;
}

.w-490p {
  width: 490px !important;
}

.w-495p {
  width: 495px !important;
}

.w-500p {
  width: 500px !important;
}

.w-505p {
  width: 505px !important;
}

.w-510p {
  width: 510px !important;
}

.w-515p {
  width: 515px !important;
}

.w-520p {
  width: 520px !important;
}

.w-525p {
  width: 525px !important;
}

.w-530p {
  width: 530px !important;
}

.w-535p {
  width: 535px !important;
}

.w-540p {
  width: 540px !important;
}

.w-545p {
  width: 545px !important;
}

.w-550p {
  width: 550px !important;
}

.w-555p {
  width: 555px !important;
}

.w-560p {
  width: 560px !important;
}

.w-565p {
  width: 565px !important;
}

.w-570p {
  width: 570px !important;
}

.w-575p {
  width: 575px !important;
}

.w-580p {
  width: 580px !important;
}

.w-585p {
  width: 585px !important;
}

.w-590p {
  width: 590px !important;
}

.w-595p {
  width: 595px !important;
}

.w-600p {
  width: 600px !important;
}

.w-605p {
  width: 605px !important;
}

.w-610p {
  width: 610px !important;
}

.w-615p {
  width: 615px !important;
}

.w-620p {
  width: 620px !important;
}

.w-625p {
  width: 625px !important;
}

.w-630p {
  width: 630px !important;
}

.w-635p {
  width: 635px !important;
}

.w-640p {
  width: 640px !important;
}

.w-645p {
  width: 645px !important;
}

.w-650p {
  width: 650px !important;
}

.w-655p {
  width: 655px !important;
}

.w-660p {
  width: 660px !important;
}

.w-665p {
  width: 665px !important;
}

.w-670p {
  width: 670px !important;
}

.w-675p {
  width: 675px !important;
}

.w-680p {
  width: 680px !important;
}

.w-685p {
  width: 685px !important;
}

.w-690p {
  width: 690px !important;
}

.w-695p {
  width: 695px !important;
}

.w-700p {
  width: 700px !important;
}

.w-705p {
  width: 705px !important;
}

.w-710p {
  width: 710px !important;
}

.w-715p {
  width: 715px !important;
}

.w-720p {
  width: 720px !important;
}

.w-725p {
  width: 725px !important;
}

.w-730p {
  width: 730px !important;
}

.w-735p {
  width: 735px !important;
}

.w-740p {
  width: 740px !important;
}

.w-745p {
  width: 745px !important;
}

.w-750p {
  width: 750px !important;
}

.w-755p {
  width: 755px !important;
}

.w-760p {
  width: 760px !important;
}

.w-765p {
  width: 765px !important;
}

.w-770p {
  width: 770px !important;
}

.w-775p {
  width: 775px !important;
}

.w-780p {
  width: 780px !important;
}

.w-785p {
  width: 785px !important;
}

.w-790p {
  width: 790px !important;
}

.w-795p {
  width: 795px !important;
}

.w-800p {
  width: 800px !important;
}

@media (min-width: 576px) {
  .w-sm-25p {
    width: 25px !important;
  }

  .w-sm-30p {
    width: 30px !important;
  }

  .w-sm-35p {
    width: 35px !important;
  }

  .w-sm-40p {
    width: 40px !important;
  }

  .w-sm-45p {
    width: 45px !important;
  }

  .w-sm-50p {
    width: 50px !important;
  }

  .w-sm-55p {
    width: 55px !important;
  }

  .w-sm-60p {
    width: 60px !important;
  }

  .w-sm-65p {
    width: 65px !important;
  }

  .w-sm-70p {
    width: 70px !important;
  }

  .w-sm-75p {
    width: 75px !important;
  }

  .w-sm-80p {
    width: 80px !important;
  }

  .w-sm-85p {
    width: 85px !important;
  }

  .w-sm-90p {
    width: 90px !important;
  }

  .w-sm-95p {
    width: 95px !important;
  }

  .w-sm-100p {
    width: 100px !important;
  }

  .w-sm-105p {
    width: 105px !important;
  }

  .w-sm-110p {
    width: 110px !important;
  }

  .w-sm-115p {
    width: 115px !important;
  }

  .w-sm-120p {
    width: 120px !important;
  }

  .w-sm-125p {
    width: 125px !important;
  }

  .w-sm-130p {
    width: 130px !important;
  }

  .w-sm-135p {
    width: 135px !important;
  }

  .w-sm-140p {
    width: 140px !important;
  }

  .w-sm-145p {
    width: 145px !important;
  }

  .w-sm-150p {
    width: 150px !important;
  }

  .w-sm-155p {
    width: 155px !important;
  }

  .w-sm-160p {
    width: 160px !important;
  }

  .w-sm-165p {
    width: 165px !important;
  }

  .w-sm-170p {
    width: 170px !important;
  }

  .w-sm-175p {
    width: 175px !important;
  }

  .w-sm-180p {
    width: 180px !important;
  }

  .w-sm-185p {
    width: 185px !important;
  }

  .w-sm-190p {
    width: 190px !important;
  }

  .w-sm-195p {
    width: 195px !important;
  }

  .w-sm-200p {
    width: 200px !important;
  }

  .w-sm-205p {
    width: 205px !important;
  }

  .w-sm-210p {
    width: 210px !important;
  }

  .w-sm-215p {
    width: 215px !important;
  }

  .w-sm-220p {
    width: 220px !important;
  }

  .w-sm-225p {
    width: 225px !important;
  }

  .w-sm-230p {
    width: 230px !important;
  }

  .w-sm-235p {
    width: 235px !important;
  }

  .w-sm-240p {
    width: 240px !important;
  }

  .w-sm-245p {
    width: 245px !important;
  }

  .w-sm-250p {
    width: 250px !important;
  }

  .w-sm-255p {
    width: 255px !important;
  }

  .w-sm-260p {
    width: 260px !important;
  }

  .w-sm-265p {
    width: 265px !important;
  }

  .w-sm-270p {
    width: 270px !important;
  }

  .w-sm-275p {
    width: 275px !important;
  }

  .w-sm-280p {
    width: 280px !important;
  }

  .w-sm-285p {
    width: 285px !important;
  }

  .w-sm-290p {
    width: 290px !important;
  }

  .w-sm-295p {
    width: 295px !important;
  }

  .w-sm-300p {
    width: 300px !important;
  }

  .w-sm-305p {
    width: 305px !important;
  }

  .w-sm-310p {
    width: 310px !important;
  }

  .w-sm-315p {
    width: 315px !important;
  }

  .w-sm-320p {
    width: 320px !important;
  }

  .w-sm-325p {
    width: 325px !important;
  }

  .w-sm-330p {
    width: 330px !important;
  }

  .w-sm-335p {
    width: 335px !important;
  }

  .w-sm-340p {
    width: 340px !important;
  }

  .w-sm-345p {
    width: 345px !important;
  }

  .w-sm-350p {
    width: 350px !important;
  }

  .w-sm-355p {
    width: 355px !important;
  }

  .w-sm-360p {
    width: 360px !important;
  }

  .w-sm-365p {
    width: 365px !important;
  }

  .w-sm-370p {
    width: 370px !important;
  }

  .w-sm-375p {
    width: 375px !important;
  }

  .w-sm-380p {
    width: 380px !important;
  }

  .w-sm-385p {
    width: 385px !important;
  }

  .w-sm-390p {
    width: 390px !important;
  }

  .w-sm-395p {
    width: 395px !important;
  }

  .w-sm-400p {
    width: 400px !important;
  }

  .w-sm-405p {
    width: 405px !important;
  }

  .w-sm-410p {
    width: 410px !important;
  }

  .w-sm-415p {
    width: 415px !important;
  }

  .w-sm-420p {
    width: 420px !important;
  }

  .w-sm-425p {
    width: 425px !important;
  }

  .w-sm-430p {
    width: 430px !important;
  }

  .w-sm-435p {
    width: 435px !important;
  }

  .w-sm-440p {
    width: 440px !important;
  }

  .w-sm-445p {
    width: 445px !important;
  }

  .w-sm-450p {
    width: 450px !important;
  }

  .w-sm-455p {
    width: 455px !important;
  }

  .w-sm-460p {
    width: 460px !important;
  }

  .w-sm-465p {
    width: 465px !important;
  }

  .w-sm-470p {
    width: 470px !important;
  }

  .w-sm-475p {
    width: 475px !important;
  }

  .w-sm-480p {
    width: 480px !important;
  }

  .w-sm-485p {
    width: 485px !important;
  }

  .w-sm-490p {
    width: 490px !important;
  }

  .w-sm-495p {
    width: 495px !important;
  }

  .w-sm-500p {
    width: 500px !important;
  }

  .w-sm-505p {
    width: 505px !important;
  }

  .w-sm-510p {
    width: 510px !important;
  }

  .w-sm-515p {
    width: 515px !important;
  }

  .w-sm-520p {
    width: 520px !important;
  }

  .w-sm-525p {
    width: 525px !important;
  }

  .w-sm-530p {
    width: 530px !important;
  }

  .w-sm-535p {
    width: 535px !important;
  }

  .w-sm-540p {
    width: 540px !important;
  }

  .w-sm-545p {
    width: 545px !important;
  }

  .w-sm-550p {
    width: 550px !important;
  }

  .w-sm-555p {
    width: 555px !important;
  }

  .w-sm-560p {
    width: 560px !important;
  }

  .w-sm-565p {
    width: 565px !important;
  }

  .w-sm-570p {
    width: 570px !important;
  }

  .w-sm-575p {
    width: 575px !important;
  }

  .w-sm-580p {
    width: 580px !important;
  }

  .w-sm-585p {
    width: 585px !important;
  }

  .w-sm-590p {
    width: 590px !important;
  }

  .w-sm-595p {
    width: 595px !important;
  }

  .w-sm-600p {
    width: 600px !important;
  }

  .w-sm-605p {
    width: 605px !important;
  }

  .w-sm-610p {
    width: 610px !important;
  }

  .w-sm-615p {
    width: 615px !important;
  }

  .w-sm-620p {
    width: 620px !important;
  }

  .w-sm-625p {
    width: 625px !important;
  }

  .w-sm-630p {
    width: 630px !important;
  }

  .w-sm-635p {
    width: 635px !important;
  }

  .w-sm-640p {
    width: 640px !important;
  }

  .w-sm-645p {
    width: 645px !important;
  }

  .w-sm-650p {
    width: 650px !important;
  }

  .w-sm-655p {
    width: 655px !important;
  }

  .w-sm-660p {
    width: 660px !important;
  }

  .w-sm-665p {
    width: 665px !important;
  }

  .w-sm-670p {
    width: 670px !important;
  }

  .w-sm-675p {
    width: 675px !important;
  }

  .w-sm-680p {
    width: 680px !important;
  }

  .w-sm-685p {
    width: 685px !important;
  }

  .w-sm-690p {
    width: 690px !important;
  }

  .w-sm-695p {
    width: 695px !important;
  }

  .w-sm-700p {
    width: 700px !important;
  }

  .w-sm-705p {
    width: 705px !important;
  }

  .w-sm-710p {
    width: 710px !important;
  }

  .w-sm-715p {
    width: 715px !important;
  }

  .w-sm-720p {
    width: 720px !important;
  }

  .w-sm-725p {
    width: 725px !important;
  }

  .w-sm-730p {
    width: 730px !important;
  }

  .w-sm-735p {
    width: 735px !important;
  }

  .w-sm-740p {
    width: 740px !important;
  }

  .w-sm-745p {
    width: 745px !important;
  }

  .w-sm-750p {
    width: 750px !important;
  }

  .w-sm-755p {
    width: 755px !important;
  }

  .w-sm-760p {
    width: 760px !important;
  }

  .w-sm-765p {
    width: 765px !important;
  }

  .w-sm-770p {
    width: 770px !important;
  }

  .w-sm-775p {
    width: 775px !important;
  }

  .w-sm-780p {
    width: 780px !important;
  }

  .w-sm-785p {
    width: 785px !important;
  }

  .w-sm-790p {
    width: 790px !important;
  }

  .w-sm-795p {
    width: 795px !important;
  }

  .w-sm-800p {
    width: 800px !important;
  }
}
@media (min-width: 768px) {
  .w-md-25p {
    width: 25px !important;
  }

  .w-md-30p {
    width: 30px !important;
  }

  .w-md-35p {
    width: 35px !important;
  }

  .w-md-40p {
    width: 40px !important;
  }

  .w-md-45p {
    width: 45px !important;
  }

  .w-md-50p {
    width: 50px !important;
  }

  .w-md-55p {
    width: 55px !important;
  }

  .w-md-60p {
    width: 60px !important;
  }

  .w-md-65p {
    width: 65px !important;
  }

  .w-md-70p {
    width: 70px !important;
  }

  .w-md-75p {
    width: 75px !important;
  }

  .w-md-80p {
    width: 80px !important;
  }

  .w-md-85p {
    width: 85px !important;
  }

  .w-md-90p {
    width: 90px !important;
  }

  .w-md-95p {
    width: 95px !important;
  }

  .w-md-100p {
    width: 100px !important;
  }

  .w-md-105p {
    width: 105px !important;
  }

  .w-md-110p {
    width: 110px !important;
  }

  .w-md-115p {
    width: 115px !important;
  }

  .w-md-120p {
    width: 120px !important;
  }

  .w-md-125p {
    width: 125px !important;
  }

  .w-md-130p {
    width: 130px !important;
  }

  .w-md-135p {
    width: 135px !important;
  }

  .w-md-140p {
    width: 140px !important;
  }

  .w-md-145p {
    width: 145px !important;
  }

  .w-md-150p {
    width: 150px !important;
  }

  .w-md-155p {
    width: 155px !important;
  }

  .w-md-160p {
    width: 160px !important;
  }

  .w-md-165p {
    width: 165px !important;
  }

  .w-md-170p {
    width: 170px !important;
  }

  .w-md-175p {
    width: 175px !important;
  }

  .w-md-180p {
    width: 180px !important;
  }

  .w-md-185p {
    width: 185px !important;
  }

  .w-md-190p {
    width: 190px !important;
  }

  .w-md-195p {
    width: 195px !important;
  }

  .w-md-200p {
    width: 200px !important;
  }

  .w-md-205p {
    width: 205px !important;
  }

  .w-md-210p {
    width: 210px !important;
  }

  .w-md-215p {
    width: 215px !important;
  }

  .w-md-220p {
    width: 220px !important;
  }

  .w-md-225p {
    width: 225px !important;
  }

  .w-md-230p {
    width: 230px !important;
  }

  .w-md-235p {
    width: 235px !important;
  }

  .w-md-240p {
    width: 240px !important;
  }

  .w-md-245p {
    width: 245px !important;
  }

  .w-md-250p {
    width: 250px !important;
  }

  .w-md-255p {
    width: 255px !important;
  }

  .w-md-260p {
    width: 260px !important;
  }

  .w-md-265p {
    width: 265px !important;
  }

  .w-md-270p {
    width: 270px !important;
  }

  .w-md-275p {
    width: 275px !important;
  }

  .w-md-280p {
    width: 280px !important;
  }

  .w-md-285p {
    width: 285px !important;
  }

  .w-md-290p {
    width: 290px !important;
  }

  .w-md-295p {
    width: 295px !important;
  }

  .w-md-300p {
    width: 300px !important;
  }

  .w-md-305p {
    width: 305px !important;
  }

  .w-md-310p {
    width: 310px !important;
  }

  .w-md-315p {
    width: 315px !important;
  }

  .w-md-320p {
    width: 320px !important;
  }

  .w-md-325p {
    width: 325px !important;
  }

  .w-md-330p {
    width: 330px !important;
  }

  .w-md-335p {
    width: 335px !important;
  }

  .w-md-340p {
    width: 340px !important;
  }

  .w-md-345p {
    width: 345px !important;
  }

  .w-md-350p {
    width: 350px !important;
  }

  .w-md-355p {
    width: 355px !important;
  }

  .w-md-360p {
    width: 360px !important;
  }

  .w-md-365p {
    width: 365px !important;
  }

  .w-md-370p {
    width: 370px !important;
  }

  .w-md-375p {
    width: 375px !important;
  }

  .w-md-380p {
    width: 380px !important;
  }

  .w-md-385p {
    width: 385px !important;
  }

  .w-md-390p {
    width: 390px !important;
  }

  .w-md-395p {
    width: 395px !important;
  }

  .w-md-400p {
    width: 400px !important;
  }

  .w-md-405p {
    width: 405px !important;
  }

  .w-md-410p {
    width: 410px !important;
  }

  .w-md-415p {
    width: 415px !important;
  }

  .w-md-420p {
    width: 420px !important;
  }

  .w-md-425p {
    width: 425px !important;
  }

  .w-md-430p {
    width: 430px !important;
  }

  .w-md-435p {
    width: 435px !important;
  }

  .w-md-440p {
    width: 440px !important;
  }

  .w-md-445p {
    width: 445px !important;
  }

  .w-md-450p {
    width: 450px !important;
  }

  .w-md-455p {
    width: 455px !important;
  }

  .w-md-460p {
    width: 460px !important;
  }

  .w-md-465p {
    width: 465px !important;
  }

  .w-md-470p {
    width: 470px !important;
  }

  .w-md-475p {
    width: 475px !important;
  }

  .w-md-480p {
    width: 480px !important;
  }

  .w-md-485p {
    width: 485px !important;
  }

  .w-md-490p {
    width: 490px !important;
  }

  .w-md-495p {
    width: 495px !important;
  }

  .w-md-500p {
    width: 500px !important;
  }

  .w-md-505p {
    width: 505px !important;
  }

  .w-md-510p {
    width: 510px !important;
  }

  .w-md-515p {
    width: 515px !important;
  }

  .w-md-520p {
    width: 520px !important;
  }

  .w-md-525p {
    width: 525px !important;
  }

  .w-md-530p {
    width: 530px !important;
  }

  .w-md-535p {
    width: 535px !important;
  }

  .w-md-540p {
    width: 540px !important;
  }

  .w-md-545p {
    width: 545px !important;
  }

  .w-md-550p {
    width: 550px !important;
  }

  .w-md-555p {
    width: 555px !important;
  }

  .w-md-560p {
    width: 560px !important;
  }

  .w-md-565p {
    width: 565px !important;
  }

  .w-md-570p {
    width: 570px !important;
  }

  .w-md-575p {
    width: 575px !important;
  }

  .w-md-580p {
    width: 580px !important;
  }

  .w-md-585p {
    width: 585px !important;
  }

  .w-md-590p {
    width: 590px !important;
  }

  .w-md-595p {
    width: 595px !important;
  }

  .w-md-600p {
    width: 600px !important;
  }

  .w-md-605p {
    width: 605px !important;
  }

  .w-md-610p {
    width: 610px !important;
  }

  .w-md-615p {
    width: 615px !important;
  }

  .w-md-620p {
    width: 620px !important;
  }

  .w-md-625p {
    width: 625px !important;
  }

  .w-md-630p {
    width: 630px !important;
  }

  .w-md-635p {
    width: 635px !important;
  }

  .w-md-640p {
    width: 640px !important;
  }

  .w-md-645p {
    width: 645px !important;
  }

  .w-md-650p {
    width: 650px !important;
  }

  .w-md-655p {
    width: 655px !important;
  }

  .w-md-660p {
    width: 660px !important;
  }

  .w-md-665p {
    width: 665px !important;
  }

  .w-md-670p {
    width: 670px !important;
  }

  .w-md-675p {
    width: 675px !important;
  }

  .w-md-680p {
    width: 680px !important;
  }

  .w-md-685p {
    width: 685px !important;
  }

  .w-md-690p {
    width: 690px !important;
  }

  .w-md-695p {
    width: 695px !important;
  }

  .w-md-700p {
    width: 700px !important;
  }

  .w-md-705p {
    width: 705px !important;
  }

  .w-md-710p {
    width: 710px !important;
  }

  .w-md-715p {
    width: 715px !important;
  }

  .w-md-720p {
    width: 720px !important;
  }

  .w-md-725p {
    width: 725px !important;
  }

  .w-md-730p {
    width: 730px !important;
  }

  .w-md-735p {
    width: 735px !important;
  }

  .w-md-740p {
    width: 740px !important;
  }

  .w-md-745p {
    width: 745px !important;
  }

  .w-md-750p {
    width: 750px !important;
  }

  .w-md-755p {
    width: 755px !important;
  }

  .w-md-760p {
    width: 760px !important;
  }

  .w-md-765p {
    width: 765px !important;
  }

  .w-md-770p {
    width: 770px !important;
  }

  .w-md-775p {
    width: 775px !important;
  }

  .w-md-780p {
    width: 780px !important;
  }

  .w-md-785p {
    width: 785px !important;
  }

  .w-md-790p {
    width: 790px !important;
  }

  .w-md-795p {
    width: 795px !important;
  }

  .w-md-800p {
    width: 800px !important;
  }
}
@media (min-width: 992px) {
  .w-lg-25p {
    width: 25px !important;
  }

  .w-lg-30p {
    width: 30px !important;
  }

  .w-lg-35p {
    width: 35px !important;
  }

  .w-lg-40p {
    width: 40px !important;
  }

  .w-lg-45p {
    width: 45px !important;
  }

  .w-lg-50p {
    width: 50px !important;
  }

  .w-lg-55p {
    width: 55px !important;
  }

  .w-lg-60p {
    width: 60px !important;
  }

  .w-lg-65p {
    width: 65px !important;
  }

  .w-lg-70p {
    width: 70px !important;
  }

  .w-lg-75p {
    width: 75px !important;
  }

  .w-lg-80p {
    width: 80px !important;
  }

  .w-lg-85p {
    width: 85px !important;
  }

  .w-lg-90p {
    width: 90px !important;
  }

  .w-lg-95p {
    width: 95px !important;
  }

  .w-lg-100p {
    width: 100px !important;
  }

  .w-lg-105p {
    width: 105px !important;
  }

  .w-lg-110p {
    width: 110px !important;
  }

  .w-lg-115p {
    width: 115px !important;
  }

  .w-lg-120p {
    width: 120px !important;
  }

  .w-lg-125p {
    width: 125px !important;
  }

  .w-lg-130p {
    width: 130px !important;
  }

  .w-lg-135p {
    width: 135px !important;
  }

  .w-lg-140p {
    width: 140px !important;
  }

  .w-lg-145p {
    width: 145px !important;
  }

  .w-lg-150p {
    width: 150px !important;
  }

  .w-lg-155p {
    width: 155px !important;
  }

  .w-lg-160p {
    width: 160px !important;
  }

  .w-lg-165p {
    width: 165px !important;
  }

  .w-lg-170p {
    width: 170px !important;
  }

  .w-lg-175p {
    width: 175px !important;
  }

  .w-lg-180p {
    width: 180px !important;
  }

  .w-lg-185p {
    width: 185px !important;
  }

  .w-lg-190p {
    width: 190px !important;
  }

  .w-lg-195p {
    width: 195px !important;
  }

  .w-lg-200p {
    width: 200px !important;
  }

  .w-lg-205p {
    width: 205px !important;
  }

  .w-lg-210p {
    width: 210px !important;
  }

  .w-lg-215p {
    width: 215px !important;
  }

  .w-lg-220p {
    width: 220px !important;
  }

  .w-lg-225p {
    width: 225px !important;
  }

  .w-lg-230p {
    width: 230px !important;
  }

  .w-lg-235p {
    width: 235px !important;
  }

  .w-lg-240p {
    width: 240px !important;
  }

  .w-lg-245p {
    width: 245px !important;
  }

  .w-lg-250p {
    width: 250px !important;
  }

  .w-lg-255p {
    width: 255px !important;
  }

  .w-lg-260p {
    width: 260px !important;
  }

  .w-lg-265p {
    width: 265px !important;
  }

  .w-lg-270p {
    width: 270px !important;
  }

  .w-lg-275p {
    width: 275px !important;
  }

  .w-lg-280p {
    width: 280px !important;
  }

  .w-lg-285p {
    width: 285px !important;
  }

  .w-lg-290p {
    width: 290px !important;
  }

  .w-lg-295p {
    width: 295px !important;
  }

  .w-lg-300p {
    width: 300px !important;
  }

  .w-lg-305p {
    width: 305px !important;
  }

  .w-lg-310p {
    width: 310px !important;
  }

  .w-lg-315p {
    width: 315px !important;
  }

  .w-lg-320p {
    width: 320px !important;
  }

  .w-lg-325p {
    width: 325px !important;
  }

  .w-lg-330p {
    width: 330px !important;
  }

  .w-lg-335p {
    width: 335px !important;
  }

  .w-lg-340p {
    width: 340px !important;
  }

  .w-lg-345p {
    width: 345px !important;
  }

  .w-lg-350p {
    width: 350px !important;
  }

  .w-lg-355p {
    width: 355px !important;
  }

  .w-lg-360p {
    width: 360px !important;
  }

  .w-lg-365p {
    width: 365px !important;
  }

  .w-lg-370p {
    width: 370px !important;
  }

  .w-lg-375p {
    width: 375px !important;
  }

  .w-lg-380p {
    width: 380px !important;
  }

  .w-lg-385p {
    width: 385px !important;
  }

  .w-lg-390p {
    width: 390px !important;
  }

  .w-lg-395p {
    width: 395px !important;
  }

  .w-lg-400p {
    width: 400px !important;
  }

  .w-lg-405p {
    width: 405px !important;
  }

  .w-lg-410p {
    width: 410px !important;
  }

  .w-lg-415p {
    width: 415px !important;
  }

  .w-lg-420p {
    width: 420px !important;
  }

  .w-lg-425p {
    width: 425px !important;
  }

  .w-lg-430p {
    width: 430px !important;
  }

  .w-lg-435p {
    width: 435px !important;
  }

  .w-lg-440p {
    width: 440px !important;
  }

  .w-lg-445p {
    width: 445px !important;
  }

  .w-lg-450p {
    width: 450px !important;
  }

  .w-lg-455p {
    width: 455px !important;
  }

  .w-lg-460p {
    width: 460px !important;
  }

  .w-lg-465p {
    width: 465px !important;
  }

  .w-lg-470p {
    width: 470px !important;
  }

  .w-lg-475p {
    width: 475px !important;
  }

  .w-lg-480p {
    width: 480px !important;
  }

  .w-lg-485p {
    width: 485px !important;
  }

  .w-lg-490p {
    width: 490px !important;
  }

  .w-lg-495p {
    width: 495px !important;
  }

  .w-lg-500p {
    width: 500px !important;
  }

  .w-lg-505p {
    width: 505px !important;
  }

  .w-lg-510p {
    width: 510px !important;
  }

  .w-lg-515p {
    width: 515px !important;
  }

  .w-lg-520p {
    width: 520px !important;
  }

  .w-lg-525p {
    width: 525px !important;
  }

  .w-lg-530p {
    width: 530px !important;
  }

  .w-lg-535p {
    width: 535px !important;
  }

  .w-lg-540p {
    width: 540px !important;
  }

  .w-lg-545p {
    width: 545px !important;
  }

  .w-lg-550p {
    width: 550px !important;
  }

  .w-lg-555p {
    width: 555px !important;
  }

  .w-lg-560p {
    width: 560px !important;
  }

  .w-lg-565p {
    width: 565px !important;
  }

  .w-lg-570p {
    width: 570px !important;
  }

  .w-lg-575p {
    width: 575px !important;
  }

  .w-lg-580p {
    width: 580px !important;
  }

  .w-lg-585p {
    width: 585px !important;
  }

  .w-lg-590p {
    width: 590px !important;
  }

  .w-lg-595p {
    width: 595px !important;
  }

  .w-lg-600p {
    width: 600px !important;
  }

  .w-lg-605p {
    width: 605px !important;
  }

  .w-lg-610p {
    width: 610px !important;
  }

  .w-lg-615p {
    width: 615px !important;
  }

  .w-lg-620p {
    width: 620px !important;
  }

  .w-lg-625p {
    width: 625px !important;
  }

  .w-lg-630p {
    width: 630px !important;
  }

  .w-lg-635p {
    width: 635px !important;
  }

  .w-lg-640p {
    width: 640px !important;
  }

  .w-lg-645p {
    width: 645px !important;
  }

  .w-lg-650p {
    width: 650px !important;
  }

  .w-lg-655p {
    width: 655px !important;
  }

  .w-lg-660p {
    width: 660px !important;
  }

  .w-lg-665p {
    width: 665px !important;
  }

  .w-lg-670p {
    width: 670px !important;
  }

  .w-lg-675p {
    width: 675px !important;
  }

  .w-lg-680p {
    width: 680px !important;
  }

  .w-lg-685p {
    width: 685px !important;
  }

  .w-lg-690p {
    width: 690px !important;
  }

  .w-lg-695p {
    width: 695px !important;
  }

  .w-lg-700p {
    width: 700px !important;
  }

  .w-lg-705p {
    width: 705px !important;
  }

  .w-lg-710p {
    width: 710px !important;
  }

  .w-lg-715p {
    width: 715px !important;
  }

  .w-lg-720p {
    width: 720px !important;
  }

  .w-lg-725p {
    width: 725px !important;
  }

  .w-lg-730p {
    width: 730px !important;
  }

  .w-lg-735p {
    width: 735px !important;
  }

  .w-lg-740p {
    width: 740px !important;
  }

  .w-lg-745p {
    width: 745px !important;
  }

  .w-lg-750p {
    width: 750px !important;
  }

  .w-lg-755p {
    width: 755px !important;
  }

  .w-lg-760p {
    width: 760px !important;
  }

  .w-lg-765p {
    width: 765px !important;
  }

  .w-lg-770p {
    width: 770px !important;
  }

  .w-lg-775p {
    width: 775px !important;
  }

  .w-lg-780p {
    width: 780px !important;
  }

  .w-lg-785p {
    width: 785px !important;
  }

  .w-lg-790p {
    width: 790px !important;
  }

  .w-lg-795p {
    width: 795px !important;
  }

  .w-lg-800p {
    width: 800px !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-25p {
    width: 25px !important;
  }

  .w-xl-30p {
    width: 30px !important;
  }

  .w-xl-35p {
    width: 35px !important;
  }

  .w-xl-40p {
    width: 40px !important;
  }

  .w-xl-45p {
    width: 45px !important;
  }

  .w-xl-50p {
    width: 50px !important;
  }

  .w-xl-55p {
    width: 55px !important;
  }

  .w-xl-60p {
    width: 60px !important;
  }

  .w-xl-65p {
    width: 65px !important;
  }

  .w-xl-70p {
    width: 70px !important;
  }

  .w-xl-75p {
    width: 75px !important;
  }

  .w-xl-80p {
    width: 80px !important;
  }

  .w-xl-85p {
    width: 85px !important;
  }

  .w-xl-90p {
    width: 90px !important;
  }

  .w-xl-95p {
    width: 95px !important;
  }

  .w-xl-100p {
    width: 100px !important;
  }

  .w-xl-105p {
    width: 105px !important;
  }

  .w-xl-110p {
    width: 110px !important;
  }

  .w-xl-115p {
    width: 115px !important;
  }

  .w-xl-120p {
    width: 120px !important;
  }

  .w-xl-125p {
    width: 125px !important;
  }

  .w-xl-130p {
    width: 130px !important;
  }

  .w-xl-135p {
    width: 135px !important;
  }

  .w-xl-140p {
    width: 140px !important;
  }

  .w-xl-145p {
    width: 145px !important;
  }

  .w-xl-150p {
    width: 150px !important;
  }

  .w-xl-155p {
    width: 155px !important;
  }

  .w-xl-160p {
    width: 160px !important;
  }

  .w-xl-165p {
    width: 165px !important;
  }

  .w-xl-170p {
    width: 170px !important;
  }

  .w-xl-175p {
    width: 175px !important;
  }

  .w-xl-180p {
    width: 180px !important;
  }

  .w-xl-185p {
    width: 185px !important;
  }

  .w-xl-190p {
    width: 190px !important;
  }

  .w-xl-195p {
    width: 195px !important;
  }

  .w-xl-200p {
    width: 200px !important;
  }

  .w-xl-205p {
    width: 205px !important;
  }

  .w-xl-210p {
    width: 210px !important;
  }

  .w-xl-215p {
    width: 215px !important;
  }

  .w-xl-220p {
    width: 220px !important;
  }

  .w-xl-225p {
    width: 225px !important;
  }

  .w-xl-230p {
    width: 230px !important;
  }

  .w-xl-235p {
    width: 235px !important;
  }

  .w-xl-240p {
    width: 240px !important;
  }

  .w-xl-245p {
    width: 245px !important;
  }

  .w-xl-250p {
    width: 250px !important;
  }

  .w-xl-255p {
    width: 255px !important;
  }

  .w-xl-260p {
    width: 260px !important;
  }

  .w-xl-265p {
    width: 265px !important;
  }

  .w-xl-270p {
    width: 270px !important;
  }

  .w-xl-275p {
    width: 275px !important;
  }

  .w-xl-280p {
    width: 280px !important;
  }

  .w-xl-285p {
    width: 285px !important;
  }

  .w-xl-290p {
    width: 290px !important;
  }

  .w-xl-295p {
    width: 295px !important;
  }

  .w-xl-300p {
    width: 300px !important;
  }

  .w-xl-305p {
    width: 305px !important;
  }

  .w-xl-310p {
    width: 310px !important;
  }

  .w-xl-315p {
    width: 315px !important;
  }

  .w-xl-320p {
    width: 320px !important;
  }

  .w-xl-325p {
    width: 325px !important;
  }

  .w-xl-330p {
    width: 330px !important;
  }

  .w-xl-335p {
    width: 335px !important;
  }

  .w-xl-340p {
    width: 340px !important;
  }

  .w-xl-345p {
    width: 345px !important;
  }

  .w-xl-350p {
    width: 350px !important;
  }

  .w-xl-355p {
    width: 355px !important;
  }

  .w-xl-360p {
    width: 360px !important;
  }

  .w-xl-365p {
    width: 365px !important;
  }

  .w-xl-370p {
    width: 370px !important;
  }

  .w-xl-375p {
    width: 375px !important;
  }

  .w-xl-380p {
    width: 380px !important;
  }

  .w-xl-385p {
    width: 385px !important;
  }

  .w-xl-390p {
    width: 390px !important;
  }

  .w-xl-395p {
    width: 395px !important;
  }

  .w-xl-400p {
    width: 400px !important;
  }

  .w-xl-405p {
    width: 405px !important;
  }

  .w-xl-410p {
    width: 410px !important;
  }

  .w-xl-415p {
    width: 415px !important;
  }

  .w-xl-420p {
    width: 420px !important;
  }

  .w-xl-425p {
    width: 425px !important;
  }

  .w-xl-430p {
    width: 430px !important;
  }

  .w-xl-435p {
    width: 435px !important;
  }

  .w-xl-440p {
    width: 440px !important;
  }

  .w-xl-445p {
    width: 445px !important;
  }

  .w-xl-450p {
    width: 450px !important;
  }

  .w-xl-455p {
    width: 455px !important;
  }

  .w-xl-460p {
    width: 460px !important;
  }

  .w-xl-465p {
    width: 465px !important;
  }

  .w-xl-470p {
    width: 470px !important;
  }

  .w-xl-475p {
    width: 475px !important;
  }

  .w-xl-480p {
    width: 480px !important;
  }

  .w-xl-485p {
    width: 485px !important;
  }

  .w-xl-490p {
    width: 490px !important;
  }

  .w-xl-495p {
    width: 495px !important;
  }

  .w-xl-500p {
    width: 500px !important;
  }

  .w-xl-505p {
    width: 505px !important;
  }

  .w-xl-510p {
    width: 510px !important;
  }

  .w-xl-515p {
    width: 515px !important;
  }

  .w-xl-520p {
    width: 520px !important;
  }

  .w-xl-525p {
    width: 525px !important;
  }

  .w-xl-530p {
    width: 530px !important;
  }

  .w-xl-535p {
    width: 535px !important;
  }

  .w-xl-540p {
    width: 540px !important;
  }

  .w-xl-545p {
    width: 545px !important;
  }

  .w-xl-550p {
    width: 550px !important;
  }

  .w-xl-555p {
    width: 555px !important;
  }

  .w-xl-560p {
    width: 560px !important;
  }

  .w-xl-565p {
    width: 565px !important;
  }

  .w-xl-570p {
    width: 570px !important;
  }

  .w-xl-575p {
    width: 575px !important;
  }

  .w-xl-580p {
    width: 580px !important;
  }

  .w-xl-585p {
    width: 585px !important;
  }

  .w-xl-590p {
    width: 590px !important;
  }

  .w-xl-595p {
    width: 595px !important;
  }

  .w-xl-600p {
    width: 600px !important;
  }

  .w-xl-605p {
    width: 605px !important;
  }

  .w-xl-610p {
    width: 610px !important;
  }

  .w-xl-615p {
    width: 615px !important;
  }

  .w-xl-620p {
    width: 620px !important;
  }

  .w-xl-625p {
    width: 625px !important;
  }

  .w-xl-630p {
    width: 630px !important;
  }

  .w-xl-635p {
    width: 635px !important;
  }

  .w-xl-640p {
    width: 640px !important;
  }

  .w-xl-645p {
    width: 645px !important;
  }

  .w-xl-650p {
    width: 650px !important;
  }

  .w-xl-655p {
    width: 655px !important;
  }

  .w-xl-660p {
    width: 660px !important;
  }

  .w-xl-665p {
    width: 665px !important;
  }

  .w-xl-670p {
    width: 670px !important;
  }

  .w-xl-675p {
    width: 675px !important;
  }

  .w-xl-680p {
    width: 680px !important;
  }

  .w-xl-685p {
    width: 685px !important;
  }

  .w-xl-690p {
    width: 690px !important;
  }

  .w-xl-695p {
    width: 695px !important;
  }

  .w-xl-700p {
    width: 700px !important;
  }

  .w-xl-705p {
    width: 705px !important;
  }

  .w-xl-710p {
    width: 710px !important;
  }

  .w-xl-715p {
    width: 715px !important;
  }

  .w-xl-720p {
    width: 720px !important;
  }

  .w-xl-725p {
    width: 725px !important;
  }

  .w-xl-730p {
    width: 730px !important;
  }

  .w-xl-735p {
    width: 735px !important;
  }

  .w-xl-740p {
    width: 740px !important;
  }

  .w-xl-745p {
    width: 745px !important;
  }

  .w-xl-750p {
    width: 750px !important;
  }

  .w-xl-755p {
    width: 755px !important;
  }

  .w-xl-760p {
    width: 760px !important;
  }

  .w-xl-765p {
    width: 765px !important;
  }

  .w-xl-770p {
    width: 770px !important;
  }

  .w-xl-775p {
    width: 775px !important;
  }

  .w-xl-780p {
    width: 780px !important;
  }

  .w-xl-785p {
    width: 785px !important;
  }

  .w-xl-790p {
    width: 790px !important;
  }

  .w-xl-795p {
    width: 795px !important;
  }

  .w-xl-800p {
    width: 800px !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-25p {
    width: 25px !important;
  }

  .w-xxl-30p {
    width: 30px !important;
  }

  .w-xxl-35p {
    width: 35px !important;
  }

  .w-xxl-40p {
    width: 40px !important;
  }

  .w-xxl-45p {
    width: 45px !important;
  }

  .w-xxl-50p {
    width: 50px !important;
  }

  .w-xxl-55p {
    width: 55px !important;
  }

  .w-xxl-60p {
    width: 60px !important;
  }

  .w-xxl-65p {
    width: 65px !important;
  }

  .w-xxl-70p {
    width: 70px !important;
  }

  .w-xxl-75p {
    width: 75px !important;
  }

  .w-xxl-80p {
    width: 80px !important;
  }

  .w-xxl-85p {
    width: 85px !important;
  }

  .w-xxl-90p {
    width: 90px !important;
  }

  .w-xxl-95p {
    width: 95px !important;
  }

  .w-xxl-100p {
    width: 100px !important;
  }

  .w-xxl-105p {
    width: 105px !important;
  }

  .w-xxl-110p {
    width: 110px !important;
  }

  .w-xxl-115p {
    width: 115px !important;
  }

  .w-xxl-120p {
    width: 120px !important;
  }

  .w-xxl-125p {
    width: 125px !important;
  }

  .w-xxl-130p {
    width: 130px !important;
  }

  .w-xxl-135p {
    width: 135px !important;
  }

  .w-xxl-140p {
    width: 140px !important;
  }

  .w-xxl-145p {
    width: 145px !important;
  }

  .w-xxl-150p {
    width: 150px !important;
  }

  .w-xxl-155p {
    width: 155px !important;
  }

  .w-xxl-160p {
    width: 160px !important;
  }

  .w-xxl-165p {
    width: 165px !important;
  }

  .w-xxl-170p {
    width: 170px !important;
  }

  .w-xxl-175p {
    width: 175px !important;
  }

  .w-xxl-180p {
    width: 180px !important;
  }

  .w-xxl-185p {
    width: 185px !important;
  }

  .w-xxl-190p {
    width: 190px !important;
  }

  .w-xxl-195p {
    width: 195px !important;
  }

  .w-xxl-200p {
    width: 200px !important;
  }

  .w-xxl-205p {
    width: 205px !important;
  }

  .w-xxl-210p {
    width: 210px !important;
  }

  .w-xxl-215p {
    width: 215px !important;
  }

  .w-xxl-220p {
    width: 220px !important;
  }

  .w-xxl-225p {
    width: 225px !important;
  }

  .w-xxl-230p {
    width: 230px !important;
  }

  .w-xxl-235p {
    width: 235px !important;
  }

  .w-xxl-240p {
    width: 240px !important;
  }

  .w-xxl-245p {
    width: 245px !important;
  }

  .w-xxl-250p {
    width: 250px !important;
  }

  .w-xxl-255p {
    width: 255px !important;
  }

  .w-xxl-260p {
    width: 260px !important;
  }

  .w-xxl-265p {
    width: 265px !important;
  }

  .w-xxl-270p {
    width: 270px !important;
  }

  .w-xxl-275p {
    width: 275px !important;
  }

  .w-xxl-280p {
    width: 280px !important;
  }

  .w-xxl-285p {
    width: 285px !important;
  }

  .w-xxl-290p {
    width: 290px !important;
  }

  .w-xxl-295p {
    width: 295px !important;
  }

  .w-xxl-300p {
    width: 300px !important;
  }

  .w-xxl-305p {
    width: 305px !important;
  }

  .w-xxl-310p {
    width: 310px !important;
  }

  .w-xxl-315p {
    width: 315px !important;
  }

  .w-xxl-320p {
    width: 320px !important;
  }

  .w-xxl-325p {
    width: 325px !important;
  }

  .w-xxl-330p {
    width: 330px !important;
  }

  .w-xxl-335p {
    width: 335px !important;
  }

  .w-xxl-340p {
    width: 340px !important;
  }

  .w-xxl-345p {
    width: 345px !important;
  }

  .w-xxl-350p {
    width: 350px !important;
  }

  .w-xxl-355p {
    width: 355px !important;
  }

  .w-xxl-360p {
    width: 360px !important;
  }

  .w-xxl-365p {
    width: 365px !important;
  }

  .w-xxl-370p {
    width: 370px !important;
  }

  .w-xxl-375p {
    width: 375px !important;
  }

  .w-xxl-380p {
    width: 380px !important;
  }

  .w-xxl-385p {
    width: 385px !important;
  }

  .w-xxl-390p {
    width: 390px !important;
  }

  .w-xxl-395p {
    width: 395px !important;
  }

  .w-xxl-400p {
    width: 400px !important;
  }

  .w-xxl-405p {
    width: 405px !important;
  }

  .w-xxl-410p {
    width: 410px !important;
  }

  .w-xxl-415p {
    width: 415px !important;
  }

  .w-xxl-420p {
    width: 420px !important;
  }

  .w-xxl-425p {
    width: 425px !important;
  }

  .w-xxl-430p {
    width: 430px !important;
  }

  .w-xxl-435p {
    width: 435px !important;
  }

  .w-xxl-440p {
    width: 440px !important;
  }

  .w-xxl-445p {
    width: 445px !important;
  }

  .w-xxl-450p {
    width: 450px !important;
  }

  .w-xxl-455p {
    width: 455px !important;
  }

  .w-xxl-460p {
    width: 460px !important;
  }

  .w-xxl-465p {
    width: 465px !important;
  }

  .w-xxl-470p {
    width: 470px !important;
  }

  .w-xxl-475p {
    width: 475px !important;
  }

  .w-xxl-480p {
    width: 480px !important;
  }

  .w-xxl-485p {
    width: 485px !important;
  }

  .w-xxl-490p {
    width: 490px !important;
  }

  .w-xxl-495p {
    width: 495px !important;
  }

  .w-xxl-500p {
    width: 500px !important;
  }

  .w-xxl-505p {
    width: 505px !important;
  }

  .w-xxl-510p {
    width: 510px !important;
  }

  .w-xxl-515p {
    width: 515px !important;
  }

  .w-xxl-520p {
    width: 520px !important;
  }

  .w-xxl-525p {
    width: 525px !important;
  }

  .w-xxl-530p {
    width: 530px !important;
  }

  .w-xxl-535p {
    width: 535px !important;
  }

  .w-xxl-540p {
    width: 540px !important;
  }

  .w-xxl-545p {
    width: 545px !important;
  }

  .w-xxl-550p {
    width: 550px !important;
  }

  .w-xxl-555p {
    width: 555px !important;
  }

  .w-xxl-560p {
    width: 560px !important;
  }

  .w-xxl-565p {
    width: 565px !important;
  }

  .w-xxl-570p {
    width: 570px !important;
  }

  .w-xxl-575p {
    width: 575px !important;
  }

  .w-xxl-580p {
    width: 580px !important;
  }

  .w-xxl-585p {
    width: 585px !important;
  }

  .w-xxl-590p {
    width: 590px !important;
  }

  .w-xxl-595p {
    width: 595px !important;
  }

  .w-xxl-600p {
    width: 600px !important;
  }

  .w-xxl-605p {
    width: 605px !important;
  }

  .w-xxl-610p {
    width: 610px !important;
  }

  .w-xxl-615p {
    width: 615px !important;
  }

  .w-xxl-620p {
    width: 620px !important;
  }

  .w-xxl-625p {
    width: 625px !important;
  }

  .w-xxl-630p {
    width: 630px !important;
  }

  .w-xxl-635p {
    width: 635px !important;
  }

  .w-xxl-640p {
    width: 640px !important;
  }

  .w-xxl-645p {
    width: 645px !important;
  }

  .w-xxl-650p {
    width: 650px !important;
  }

  .w-xxl-655p {
    width: 655px !important;
  }

  .w-xxl-660p {
    width: 660px !important;
  }

  .w-xxl-665p {
    width: 665px !important;
  }

  .w-xxl-670p {
    width: 670px !important;
  }

  .w-xxl-675p {
    width: 675px !important;
  }

  .w-xxl-680p {
    width: 680px !important;
  }

  .w-xxl-685p {
    width: 685px !important;
  }

  .w-xxl-690p {
    width: 690px !important;
  }

  .w-xxl-695p {
    width: 695px !important;
  }

  .w-xxl-700p {
    width: 700px !important;
  }

  .w-xxl-705p {
    width: 705px !important;
  }

  .w-xxl-710p {
    width: 710px !important;
  }

  .w-xxl-715p {
    width: 715px !important;
  }

  .w-xxl-720p {
    width: 720px !important;
  }

  .w-xxl-725p {
    width: 725px !important;
  }

  .w-xxl-730p {
    width: 730px !important;
  }

  .w-xxl-735p {
    width: 735px !important;
  }

  .w-xxl-740p {
    width: 740px !important;
  }

  .w-xxl-745p {
    width: 745px !important;
  }

  .w-xxl-750p {
    width: 750px !important;
  }

  .w-xxl-755p {
    width: 755px !important;
  }

  .w-xxl-760p {
    width: 760px !important;
  }

  .w-xxl-765p {
    width: 765px !important;
  }

  .w-xxl-770p {
    width: 770px !important;
  }

  .w-xxl-775p {
    width: 775px !important;
  }

  .w-xxl-780p {
    width: 780px !important;
  }

  .w-xxl-785p {
    width: 785px !important;
  }

  .w-xxl-790p {
    width: 790px !important;
  }

  .w-xxl-795p {
    width: 795px !important;
  }

  .w-xxl-800p {
    width: 800px !important;
  }
}
/*Width In Percentage*/
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-5 {
    width: 5% !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-95 {
    width: 95% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-5 {
    width: 5% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-5 {
    width: 5% !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-95 {
    width: 95% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-5 {
    width: 5% !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-15 {
    width: 15% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-35 {
    width: 35% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-55 {
    width: 55% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-95 {
    width: 95% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }
}
/*Max Width*/
.mw-25p {
  max-width: 25px !important;
}

.mw-50p {
  max-width: 50px !important;
}

.mw-75p {
  max-width: 75px !important;
}

.mw-100p {
  max-width: 100px !important;
}

.mw-125p {
  max-width: 125px !important;
}

.mw-150p {
  max-width: 150px !important;
}

.mw-175p {
  max-width: 175px !important;
}

.mw-200p {
  max-width: 200px !important;
}

.mw-225p {
  max-width: 225px !important;
}

.mw-250p {
  max-width: 250px !important;
}

.mw-275p {
  max-width: 275px !important;
}

.mw-300p {
  max-width: 300px !important;
}

.mw-325p {
  max-width: 325px !important;
}

.mw-350p {
  max-width: 350px !important;
}

.mw-375p {
  max-width: 375px !important;
}

.mw-400p {
  max-width: 400px !important;
}

.mw-425p {
  max-width: 425px !important;
}

.mw-450p {
  max-width: 450px !important;
}

.mw-475p {
  max-width: 475px !important;
}

.mw-500p {
  max-width: 500px !important;
}

.mw-525p {
  max-width: 525px !important;
}

.mw-550p {
  max-width: 550px !important;
}

.mw-575p {
  max-width: 575px !important;
}

.mw-600p {
  max-width: 600px !important;
}

.mw-625p {
  max-width: 625px !important;
}

.mw-650p {
  max-width: 650px !important;
}

.mw-675p {
  max-width: 675px !important;
}

.mw-700p {
  max-width: 700px !important;
}

.mw-725p {
  max-width: 725px !important;
}

.mw-750p {
  max-width: 750px !important;
}

.mw-775p {
  max-width: 775px !important;
}

.mw-800p {
  max-width: 800px !important;
}

/*Min Width*/
.mnw-0 {
  min-width: 0 !important;
}

.mnw-25p {
  min-width: 25px !important;
}

.mnw-50p {
  min-width: 50px !important;
}

.mnw-75p {
  min-width: 75px !important;
}

.mnw-100p {
  min-width: 100px !important;
}

.mnw-125p {
  min-width: 125px !important;
}

.mnw-150p {
  min-width: 150px !important;
}

.mnw-175p {
  min-width: 175px !important;
}

.mnw-200p {
  min-width: 200px !important;
}

.mnw-225p {
  min-width: 225px !important;
}

.mnw-250p {
  min-width: 250px !important;
}

.mnw-275p {
  min-width: 275px !important;
}

.mnw-300p {
  min-width: 300px !important;
}

.mnw-325p {
  min-width: 325px !important;
}

.mnw-350p {
  min-width: 350px !important;
}

.mnw-375p {
  min-width: 375px !important;
}

.mnw-400p {
  min-width: 400px !important;
}

.mnw-425p {
  min-width: 425px !important;
}

.mnw-450p {
  min-width: 450px !important;
}

.mnw-475p {
  min-width: 475px !important;
}

.mnw-500p {
  min-width: 500px !important;
}

.mnw-525p {
  min-width: 525px !important;
}

.mnw-550p {
  min-width: 550px !important;
}

.mnw-575p {
  min-width: 575px !important;
}

.mnw-600p {
  min-width: 600px !important;
}

.mnw-625p {
  min-width: 625px !important;
}

.mnw-650p {
  min-width: 650px !important;
}

.mnw-675p {
  min-width: 675px !important;
}

.mnw-700p {
  min-width: 700px !important;
}

.mnw-725p {
  min-width: 725px !important;
}

.mnw-750p {
  min-width: 750px !important;
}

.mnw-775p {
  min-width: 775px !important;
}

.mnw-800p {
  min-width: 800px !important;
}

/*Height*/
.h-0 {
  height: 0 !important;
}

.h-25p {
  height: 25px !important;
}

.h-30p {
  height: 30px !important;
}

.h-35p {
  height: 35px !important;
}

.h-40p {
  height: 40px !important;
}

.h-45p {
  height: 45px !important;
}

.h-50p {
  height: 50px !important;
}

.h-55p {
  height: 55px !important;
}

.h-60p {
  height: 60px !important;
}

.h-65p {
  height: 65px !important;
}

.h-70p {
  height: 70px !important;
}

.h-75p {
  height: 75px !important;
}

.h-80p {
  height: 80px !important;
}

.h-85p {
  height: 85px !important;
}

.h-90p {
  height: 90px !important;
}

.h-95p {
  height: 95px !important;
}

.h-100p {
  height: 100px !important;
}

.h-105p {
  height: 105px !important;
}

.h-110p {
  height: 110px !important;
}

.h-115p {
  height: 115px !important;
}

.h-120p {
  height: 120px !important;
}

.h-125p {
  height: 125px !important;
}

.h-130p {
  height: 130px !important;
}

.h-135p {
  height: 135px !important;
}

.h-140p {
  height: 140px !important;
}

.h-145p {
  height: 145px !important;
}

.h-150p {
  height: 150px !important;
}

.h-155p {
  height: 155px !important;
}

.h-160p {
  height: 160px !important;
}

.h-165p {
  height: 165px !important;
}

.h-170p {
  height: 170px !important;
}

.h-175p {
  height: 175px !important;
}

.h-180p {
  height: 180px !important;
}

.h-185p {
  height: 185px !important;
}

.h-190p {
  height: 190px !important;
}

.h-195p {
  height: 195px !important;
}

.h-200p {
  height: 200px !important;
}

.h-205p {
  height: 205px !important;
}

.h-210p {
  height: 210px !important;
}

.h-215p {
  height: 215px !important;
}

.h-220p {
  height: 220px !important;
}

.h-225p {
  height: 225px !important;
}

.h-230p {
  height: 230px !important;
}

.h-235p {
  height: 235px !important;
}

.h-240p {
  height: 240px !important;
}

.h-245p {
  height: 245px !important;
}

.h-250p {
  height: 250px !important;
}

.h-255p {
  height: 255px !important;
}

.h-260p {
  height: 260px !important;
}

.h-265p {
  height: 265px !important;
}

.h-270p {
  height: 270px !important;
}

.h-275p {
  height: 275px !important;
}

.h-280p {
  height: 280px !important;
}

.h-285p {
  height: 285px !important;
}

.h-290p {
  height: 290px !important;
}

.h-295p {
  height: 295px !important;
}

.h-300p {
  height: 300px !important;
}

.h-305p {
  height: 305px !important;
}

.h-310p {
  height: 310px !important;
}

.h-315p {
  height: 315px !important;
}

.h-320p {
  height: 320px !important;
}

.h-325p {
  height: 325px !important;
}

.h-330p {
  height: 330px !important;
}

.h-335p {
  height: 335px !important;
}

.h-340p {
  height: 340px !important;
}

.h-345p {
  height: 345px !important;
}

.h-350p {
  height: 350px !important;
}

.h-355p {
  height: 355px !important;
}

.h-360p {
  height: 360px !important;
}

.h-365p {
  height: 365px !important;
}

.h-370p {
  height: 370px !important;
}

.h-375p {
  height: 375px !important;
}

.h-380p {
  height: 380px !important;
}

.h-385p {
  height: 385px !important;
}

.h-390p {
  height: 390px !important;
}

.h-395p {
  height: 395px !important;
}

.h-400p {
  height: 400px !important;
}

.h-405p {
  height: 405px !important;
}

.h-410p {
  height: 410px !important;
}

.h-415p {
  height: 415px !important;
}

.h-420p {
  height: 420px !important;
}

.h-425p {
  height: 425px !important;
}

.h-430p {
  height: 430px !important;
}

.h-435p {
  height: 435px !important;
}

.h-440p {
  height: 440px !important;
}

.h-445p {
  height: 445px !important;
}

.h-450p {
  height: 450px !important;
}

.h-455p {
  height: 455px !important;
}

.h-460p {
  height: 460px !important;
}

.h-465p {
  height: 465px !important;
}

.h-470p {
  height: 470px !important;
}

.h-475p {
  height: 475px !important;
}

.h-480p {
  height: 480px !important;
}

.h-485p {
  height: 485px !important;
}

.h-490p {
  height: 490px !important;
}

.h-495p {
  height: 495px !important;
}

.h-500p {
  height: 500px !important;
}

.h-505p {
  height: 505px !important;
}

.h-510p {
  height: 510px !important;
}

.h-515p {
  height: 515px !important;
}

.h-520p {
  height: 520px !important;
}

.h-525p {
  height: 525px !important;
}

.h-530p {
  height: 530px !important;
}

.h-535p {
  height: 535px !important;
}

.h-540p {
  height: 540px !important;
}

.h-545p {
  height: 545px !important;
}

.h-550p {
  height: 550px !important;
}

.h-555p {
  height: 555px !important;
}

.h-560p {
  height: 560px !important;
}

.h-565p {
  height: 565px !important;
}

.h-570p {
  height: 570px !important;
}

.h-575p {
  height: 575px !important;
}

.h-580p {
  height: 580px !important;
}

.h-585p {
  height: 585px !important;
}

.h-590p {
  height: 590px !important;
}

.h-595p {
  height: 595px !important;
}

.h-600p {
  height: 600px !important;
}

.h-605p {
  height: 605px !important;
}

.h-610p {
  height: 610px !important;
}

.h-615p {
  height: 615px !important;
}

.h-620p {
  height: 620px !important;
}

.h-625p {
  height: 625px !important;
}

.h-630p {
  height: 630px !important;
}

.h-635p {
  height: 635px !important;
}

.h-640p {
  height: 640px !important;
}

.h-645p {
  height: 645px !important;
}

.h-650p {
  height: 650px !important;
}

.h-655p {
  height: 655px !important;
}

.h-660p {
  height: 660px !important;
}

.h-665p {
  height: 665px !important;
}

.h-670p {
  height: 670px !important;
}

.h-675p {
  height: 675px !important;
}

.h-680p {
  height: 680px !important;
}

.h-685p {
  height: 685px !important;
}

.h-690p {
  height: 690px !important;
}

.h-695p {
  height: 695px !important;
}

.h-700p {
  height: 700px !important;
}

.h-705p {
  height: 705px !important;
}

.h-710p {
  height: 710px !important;
}

.h-715p {
  height: 715px !important;
}

.h-720p {
  height: 720px !important;
}

.h-725p {
  height: 725px !important;
}

.h-730p {
  height: 730px !important;
}

.h-735p {
  height: 735px !important;
}

.h-740p {
  height: 740px !important;
}

.h-745p {
  height: 745px !important;
}

.h-750p {
  height: 750px !important;
}

.h-755p {
  height: 755px !important;
}

.h-760p {
  height: 760px !important;
}

.h-765p {
  height: 765px !important;
}

.h-770p {
  height: 770px !important;
}

.h-775p {
  height: 775px !important;
}

.h-780p {
  height: 780px !important;
}

.h-785p {
  height: 785px !important;
}

.h-790p {
  height: 790px !important;
}

.h-795p {
  height: 795px !important;
}

.h-800p {
  height: 800px !important;
}

/*Height In Percentage*/
.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

/*Max Height*/
.mh-100p {
  max-height: 100px !important;
}

.mh-125p {
  max-height: 125px !important;
}

.mh-150p {
  max-height: 150px !important;
}

.mh-175p {
  max-height: 175px !important;
}

.mh-200p {
  max-height: 200px !important;
}

.mh-225p {
  max-height: 225px !important;
}

.mh-250p {
  max-height: 250px !important;
}

.mh-275p {
  max-height: 275px !important;
}

.mh-300p {
  max-height: 300px !important;
}

.mh-325p {
  max-height: 325px !important;
}
.mh-330p {
  max-height: 330px !important;
}
.mh-350p {
  max-height: 350px !important;
}

.mh-375p {
  max-height: 375px !important;
}
.mh-388p {
  max-height: 388px !important;
}
.mh-400p {
  max-height: 400px !important;
}

.mh-425p {
  max-height: 425px !important;
}
.mh-430p {
  max-height: 430px !important;
}
.mh-450p {
  max-height: 450px !important;
}

.mh-475p {
  max-height: 475px !important;
}

.mh-500p {
  max-height: 500px !important;
}

.mh-525p {
  max-height: 525px !important;
}

.mh-550p {
  max-height: 550px !important;
}

.mh-575p {
  max-height: 575px !important;
}

.mh-600p {
  max-height: 600px !important;
}

.mh-625p {
  max-height: 625px !important;
}

.mh-650p {
  max-height: 650px !important;
}

.mh-675p {
  max-height: 675px !important;
}

.mh-700p {
  max-height: 700px !important;
}

.mh-725p {
  max-height: 725px !important;
}

.mh-750p {
  max-height: 750px !important;
}

.mh-775p {
  max-height: 775px !important;
}

.mh-800p {
  max-height: 800px !important;
}

/*Min Height*/
.mnh-0 {
  min-height: 0 !important;
}

.mnh-100p {
  min-height: 100px !important;
}

.mnh-125p {
  min-height: 125px !important;
}

.mnh-150p {
  min-height: 150px !important;
}

.mnh-175p {
  min-height: 175px !important;
}

.mnh-200p {
  min-height: 200px !important;
}

.mnh-225p {
  min-height: 225px !important;
}

.mnh-250p {
  min-height: 250px !important;
}

.mnh-275p {
  min-height: 275px !important;
}

.mnh-300p {
  min-height: 300px !important;
}

.mnh-325p {
  min-height: 325px !important;
}

.mnh-350p {
  min-height: 350px !important;
}

.mnh-375p {
  min-height: 375px !important;
}

.mnh-400p {
  min-height: 400px !important;
}

.mnh-425p {
  min-height: 425px !important;
}

.mnh-450p {
  min-height: 450px !important;
}

.mnh-475p {
  min-height: 475px !important;
}

.mnh-500p {
  min-height: 500px !important;
}

.mnh-525p {
  min-height: 525px !important;
}

.mnh-550p {
  min-height: 550px !important;
}

.mnh-575p {
  min-height: 575px !important;
}

.mnh-600p {
  min-height: 600px !important;
}

.mnh-625p {
  min-height: 625px !important;
}

.mnh-650p {
  min-height: 650px !important;
}

.mnh-675p {
  min-height: 675px !important;
}

.mnh-700p {
  min-height: 700px !important;
}

.mnh-725p {
  min-height: 725px !important;
}

.mnh-750p {
  min-height: 750px !important;
}

.mnh-775p {
  min-height: 775px !important;
}

.mnh-800p {
  min-height: 800px !important;
}

.mnh-100vh {
  min-height: 100vh !important;
}

/* Gutter Gap */
.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}
.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}
.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}
.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}
.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
}

/* Rounded */
.rounded-0 {
  border-radius: 0rem !important;
}

.rounded-1 {
  border-radius: 0.125rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.375rem !important;
}

.rounded-4 {
  border-radius: 0.5rem !important;
}

.rounded-5 {
  border-radius: 0.625rem !important;
}

.rounded-6 {
  border-radius: 0.75rem !important;
}

.rounded-7 {
  border-radius: 0.875rem !important;
}

.rounded-8 {
  border-radius: 1rem !important;
}

.rounded-9 {
  border-radius: 1.125rem !important;
}

.rounded-10 {
  border-radius: 1.25rem !important;
}

.rounded-11 {
  border-radius: 1.375rem !important;
}

.rounded-12 {
  border-radius: 1.5rem !important;
}

.rounded-13 {
  border-radius: 1.625rem !important;
}

.rounded-14 {
  border-radius: 1.75rem !important;
}

.rounded-15 {
  border-radius: 1.875rem !important;
}

.rounded-16 {
  border-radius: 2rem !important;
}

.rounded-17 {
  border-radius: 2.125rem !important;
}

.rounded-18 {
  border-radius: 2.25rem !important;
}

.rounded-19 {
  border-radius: 2.375rem !important;
}

.rounded-20 {
  border-radius: 2.5rem !important;
}

.rounded-21 {
  border-radius: 2.75rem !important;
}

.rounded-22 {
  border-radius: 3rem !important;
}

.rounded-23 {
  border-radius: 3.25rem !important;
}

.rounded-24 {
  border-radius: 3.5rem !important;
}

.rounded-25 {
  border-radius: 3.75rem !important;
}

.rounded-26 {
  border-radius: 4rem !important;
}

.rounded-27 {
  border-radius: 4.25rem !important;
}

.rounded-28 {
  border-radius: 4.5rem !important;
}

.rounded-29 {
  border-radius: 4.75rem !important;
}

.rounded-30 {
  border-radius: 5rem !important;
}

.rounded-31 {
  border-radius: 5.25rem !important;
}

.rounded-32 {
  border-radius: 5.5rem !important;
}

.rounded-33 {
  border-radius: 5.75rem !important;
}

.rounded-34 {
  border-radius: 6rem !important;
}

/*Font Size*/
.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.font-1 {
  font-size: 0.5rem !important;
}

.font-2 {
  font-size: 0.75rem !important;
}
.font-resize {
  font-size: 0.95rem !important;
}
.font-3 {
  font-size: 1rem !important;
}
.font-3-3 {
  font-size: 1.1rem !important;
}
.font-4 {
  font-size: 1.25rem !important;
}

.font-5 {
  font-size: 1.5rem !important;
}

.font-6 {
  font-size: 1.75rem !important;
}

.font-7 {
  font-size: 2rem !important;
}

.font-8 {
  font-size: 2.25rem !important;
}

.font-9 {
  font-size: 2.5rem !important;
}

.font-10 {
  font-size: 2.75rem !important;
}

/* Badge */
.badge {
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.5rem 0.625rem;
  font-size: 70%;
  min-width: 25px;
  letter-spacing: 0.3px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
}
.badge.badge-cyan {
  background: #18ddef;
  color: #fff;
}
.badge.badge-cyan.badge-indicator-processing:after {
  border-color: #18ddef;
}
.badge.badge-info {
  background: #18ddef;
  color: #fff;
}
.badge.badge-info.badge-indicator-processing:after {
  border-color: #18ddef;
}
.badge.badge-sky {
  background: #00b0ff;
  color: #fff;
}
.badge.badge-sky.badge-indicator-processing:after {
  border-color: #00b0ff;
}
.badge.badge-green {
  background: #00d67f;
  color: #fff;
}
.badge.badge-green.badge-indicator-processing:after {
  /* border-color: #00D67F; */
  border-color: var(--bs-body-main-color);
}
.badge.badge-success {
  /* background: #00D67F; */
  background: var(--bs-body-main-color);
  color: #fff;
}
.badge.badge-success.badge-indicator-processing:after {
  /* border-color: #00D67F; */
  border-color: var(--bs-body-main-color);
}
.badge.badge-red {
  background: #ff0000;
  color: #fff;
}
.badge.badge-red.badge-indicator-processing:after {
  border-color: #ff0000;
}
.badge.badge-danger {
  background: #ff0000;
  color: #fff;
}
.badge.badge-danger.badge-indicator-processing:after {
  border-color: #ff0000;
}
.badge.badge-yellow {
  background: #ffc400;
  color: #fff;
}
.badge.badge-yellow.badge-indicator-processing:after {
  border-color: #ffc400;
}
.badge.badge-warning {
  background: #ffc400;
  color: #fff;
}
.badge.badge-warning.badge-indicator-processing:after {
  border-color: #ffc400;
}
.badge.badge-pink {
  background: #e92990;
  color: #fff;
}
.badge.badge-pink.badge-indicator-processing:after {
  border-color: #e92990;
}
.badge.badge-purple {
  background: #c02ff3;
  color: #fff;
}
.badge.badge-purple.badge-indicator-processing:after {
  border-color: #c02ff3;
}
.badge.badge-violet {
  background: #7429f8;
  color: #fff;
}
.badge.badge-violet.badge-indicator-processing:after {
  border-color: #7429f8;
}
.badge.badge-indigo {
  background: #0069f7;
  color: #fff;
}
.badge.badge-indigo.badge-indicator-processing:after {
  border-color: #0069f7;
}
.badge.badge-blue {
  background: #298dff;
  color: #fff;
}
.badge.badge-blue.badge-indicator-processing:after {
  border-color: #298dff;
}
.badge.badge-primary {
  background: var(--bs-body-main-color);
  color: #fff;
}
.badge.badge-primary.badge-indicator-processing:after {
  border-color: var(--bs-body-main-color);
}
.badge.badge-teal {
  background: var(--bs-body-main-color);
  color: #fff;
}
.badge.badge-teal.badge-indicator-processing:after {
  border-color: var(--bs-body-main-color);
}
.badge.badge-neon {
  background: #81e230;
  color: #fff;
}
.badge.badge-neon.badge-indicator-processing:after {
  border-color: #81e230;
}
.badge.badge-lime {
  background: #c2e939;
  color: #fff;
}
.badge.badge-lime.badge-indicator-processing:after {
  border-color: #c2e939;
}
.badge.badge-sun {
  background: #ffea00;
  color: #262a2e;
}
.badge.badge-sun.badge-indicator-processing:after {
  border-color: #ffea00;
}
.badge.badge-orange {
  background: #ff9100;
  color: #fff;
}
.badge.badge-orange.badge-indicator-processing:after {
  border-color: #ff9100;
}
.badge.badge-pumpkin {
  background: #ff5700;
  color: #fff;
}
.badge.badge-pumpkin.badge-indicator-processing:after {
  border-color: #ff5700;
}
.badge.badge-smoke {
  background: #5e7d8a;
  color: #fff;
}
.badge.badge-smoke.badge-indicator-processing:after {
  border-color: #5e7d8a;
}
.badge.badge-brown {
  background: #954d15;
  color: #fff;
}
.badge.badge-brown.badge-indicator-processing:after {
  border-color: #954d15;
}
.badge.badge-gold {
  background: #c39749;
  color: #fff;
}
.badge.badge-gold.badge-indicator-processing:after {
  border-color: #c39749;
}
.badge.badge-grey {
  background: #9e9e9e;
  color: #fff;
}
.badge.badge-grey.badge-indicator-processing:after {
  border-color: #9e9e9e;
}
.badge.badge-secondary {
  background: #6f6f6f;
  color: #fff;
}
.badge.badge-secondary.badge-indicator-processing:after {
  border-color: #6f6f6f;
}
.badge.badge-light {
  background: #eaeaea;
  color: #262a2e;
}
.badge.badge-light.badge-indicator-processing:after {
  border-color: #eaeaea;
}
.badge.badge-dark {
  background: #262a2e;
  color: #fff;
}
.badge.badge-dark.badge-indicator-processing:after {
  border-color: #262a2e;
}
.badge.badge-outline {
  background: transparent;
  border: 1px solid;
}
.badge.badge-outline.badge-cyan {
  border-color: #18ddef;
  color: #18ddef;
}
.badge.badge-outline.badge-info {
  border-color: #18ddef;
  color: #18ddef;
}
.badge.badge-outline.badge-sky {
  border-color: #00b0ff;
  color: #00b0ff;
}
.badge.badge-outline.badge-green {
  border-color: #00d67f;
  color: #00ab66;
}
.badge.badge-outline.badge-success {
  border-color: #00d67f;
  color: #00ab66;
}
.badge.badge-outline.badge-red {
  border-color: #ff0000;
  color: #e60000;
}
.badge.badge-outline.badge-danger {
  border-color: #ff0000;
  color: #e60000;
}
.badge.badge-outline.badge-yellow {
  border-color: #ffc400;
  color: #cc9d00;
}
.badge.badge-outline.badge-warning {
  border-color: #ffc400;
  color: #cc9d00;
}
.badge.badge-outline.badge-grey {
  border-color: #9e9e9e;
  color: #9e9e9e;
}
.badge.badge-outline.badge-secondary {
  border-color: #6f6f6f;
  color: #6f6f6f;
}
.badge.badge-outline.badge-light {
  border-color: #eaeaea;
  color: #262a2e;
}
.badge.badge-outline.badge-dark {
  border-color: #262a2e;
  color: #262a2e;
}
.badge.badge-outline.badge-pink {
  border-color: #e92990;
  color: #e92990;
}
.badge.badge-outline.badge-purple {
  border-color: #c02ff3;
  color: #c02ff3;
}
.badge.badge-outline.badge-violet {
  border-color: #7429f8;
  color: #7429f8;
}
.badge.badge-outline.badge-indigo {
  border-color: #0069f7;
  color: #0069f7;
}
.badge.badge-outline.badge-blue {
  border-color: #298dff;
  color: #298dff;
}
