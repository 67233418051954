/*
Theme Color
By Default
Sidebar
Wrapper
Responsive Show / Hide
Theme Text
Theme Background
Theme Border
Theme Button
Scrollbar
Responsive Navbar Routes
Typewriter Effect
As Per Requirements
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* Theme Color */
:root {
  --primary-color: #0c8ce9;
  --primary-color-light: #b3dfff;
  --primary-color-dark: #03045e;
  --primary-bg-color: rgba(3, 3, 3, 0.1);
  --theme-success: #3ea743;
  --white: #fff;
  --dark: #343a40;
  --dark2: #00000020;
}

/* By Default */
a {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  margin-bottom: 0;
}
/* Input Styling: Styles for form inputs, including number input spinner hiding  */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Sidebar */
#sidebar {
  width: 70px;
  min-width: 70px;
  z-index: 1000;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--primary-border-color);
  height: 100vh;
}

#sidebar.expand {
  width: 260px;
  min-width: 260px;
}

.toggle-btn {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 1rem 1.5rem;
}

.toggle-btn i {
  font-size: 1.5rem;
  color: var(--dark);
}

.sidebar-logo {
  margin: auto 0;
}

.sidebar-logo a {
  color: var(--dark);
  font-size: 1.15rem;
  font-weight: 600;
}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
  display: none;
}

.sidebar-nav {
  padding: 0;
  flex: 1 1 auto;
}

a.sidebar-link {
  padding: 0.625rem 1.625rem;
  color: var(--dark);
  display: block;
  font-size: 0.9rem;
  white-space: nowrap;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
}

.sidebar-link i {
  font-size: 1.1rem;
  margin-right: 0.75rem;
}

a.sidebar-link:hover {
  background-color: var(--primary-bg-color);
  border-left: 3px solid var(--primary-color);
}

a.sidebar-link.active {
  background-color: var(--primary-color);
  border-left: 3px solid var(--primary-color);
  color: var(--white);
}

.sidebar-item {
  position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
  position: absolute;
  top: 0;
  left: 70px;
  background-color: #0e2238;
  padding: 0;
  min-width: 15rem;
  display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown + .sidebar-dropdown {
  display: block;
  max-height: 15em;
  width: 100%;
  opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 0.075rem 0.075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg);
  transition: all 0.2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.hide-when-collapsed {
  display: none;
}

/* Wrapper */
.wrapper {
  display: flex;
}
@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }
}
.wrapper-main {
  height: 100vh;
  width: 100%;
  overflow: auto;
  transition: all 0.35s ease-in-out;
}

/* Responsive Show / Hide */
.d-none-sm {
  display: block !important;
}
.d-block-sm {
  display: none !important;
}
@media (max-width: 600px) {
  .d-none-sm {
    display: none !important;
  }
  .d-block-sm {
    display: block !important;
  }
}

.d-none-md {
  display: block !important;
}
.d-block-md {
  display: none !important;
}
@media (max-width: 992px) {
  .d-none-md {
    display: none !important;
  }
  .d-block-md {
    display: block !important;
  }
}

/* Theme Text */
.text-primary-theme {
  color: var(--primary-color) !important;
}
.text-success-theme {
  color: var(--theme-success) !important;
}
.text-primary-light-theme {
  color: var(--primary-color-light) !important;
}
.text-primary-dark-theme {
  color: var(--primary-color-dark) !important;
}

/* Theme Background */
.bg-primary-theme {
  background-color: var(--primary-color);
}
.bg-primary-light-theme {
  background-color: var(--primary-bg-color);
}

.bg-success-theme {
  background-color: var(--theme-success);
}

/* Theme Border */
.border-primary-theme {
  border-color: var(--primary-color) !important;
}
.border-primary-light-theme {
  border-color: var(--primary-color-light) !important;
}
.border-primary-dark-theme {
  border-color: var(--primary-color-dark) !important;
}

/* Theme Button */
.btn-primary-theme {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-primary-theme:hover {
  background-color: var(--primary-color-dark);
  color: var(--white);
}

.btn-outline-primary-theme {
  color: var(--primary-color);
  background-color: transparent;
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}

.btn-outline-primary-theme:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-outline-primary-theme:active {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
  color: var(--white);
}

.btn-secondary-theme {
  background-color: #e9e9ff;
  color: var(--primary-color-dark);
  border-color: #e9e9ff;
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-secondary-theme:hover {
  background-color: #9393f7;
  border-color: #9393f7;
}
.btn-secondary-theme:active {
  background-color: var(--primary-color-dark);
  color: var(--white);
  border-color: var(--primary-color-dark);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--dark2);
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-light);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Responsive Navbar Routes */
@media (max-width: 600px) {
  .custom_navbar-collapse {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .custom_navbar-collapse.custom_show {
    display: flex;
    animation: custom_fadeIn 0.5s;
  }

  .custom_navbar-nav {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@keyframes custom_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Typewriter Effect */
.typewriter-text {
  overflow: hidden;
  border-right: 0.15em solid var(--primary-color);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 4s steps(30, end) infinite,
    blink-caret 0.5s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--primary-color);
  }
}

/* As Per Requirements */

.h-100vh {
  height: 100vh;
}

.bg-cloud {
  background: url("../Assets/Images/cloud.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  transition: background-color 0.3s ease;
}

.remove-after-icon::after {
  content: none !important;
}

.img-cover-center {
  object-fit: cover;
  object-position: center;
}
.img-contain-center {
  object-fit: contain;
  object-position: center;
}

.pointer {
  cursor: pointer;
}
.pointer-none {
  cursor: default;
}

.fs-sm {
  font-size: 12px;
}

.mt-sm-3rem {
  margin-top: 0;
}
@media (max-width: 768px) {
  .mt-sm-3rem {
    margin-top: 3rem;
  }
}

.sticky-header {
  z-index: 10;
  position: sticky;
  top: 0;
}

/* //DATA LOADER------------ */
.data-loader {
  border: 4px solid rgb(244, 229, 229);
  border-left-color: transparent;
  border-radius: 50%;
}

.data-loader {
  border: 4px solid rgba(240, 235, 235, 0.971);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.data-loader {
  border: 4px solid rgb(248, 245, 245);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer !important;
}
.text-link {
  color: #6c757d;
}
.text-link:hover {
  cursor: pointer !important;
  text-decoration: underline;
  color: var(--primary-color-dark);
}

/* Skeleton Loader */
.skeleton-loader {
  margin-bottom: 10px;
  border: 1px solid #f0f8ff;
  padding: 15px;
  background-color: #f0f8ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.skeleton-loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation 1.2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-loader > div {
  background-color: rgba(185, 217, 235, 0.7);
}

.circle-skeleton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.h1-skeleton {
  height: 24px;
  width: 100%;
}

.h2-skeleton {
  height: 20px;
  width: 100%;
}

.h3-skeleton {
  height: 18px;
  width: 100%;
}

.h4-skeleton {
  height: 16px;
  width: 100%;
}

.h5-skeleton {
  height: 14px;
  width: 100%;
}

.p-skeleton {
  height: 16px;
  width: 100%;
}

.p-skeleton.short {
  width: 92%;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Complaint */
.recent-complaint-section {
  overflow: auto;
  max-height: calc(100vh - 320px);
}

/*Pagination*/
.pagination-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-link {
  color: #262a2e;
  border-color: #d8d8d8;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}
.page-link:focus,
.page-link:hover {
  color: #262a2e;
  box-shadow: none;
  background-color: #eaeaea;
  border-color: #d8d8d8;
}

.pagination .page-item.active .page-link {
  background: var(--bs-body-main-color);
  border-color: var(--bs-body-main-color);
  color: #fff;
}
.pagination .page-item.disabled .page-link {
  color: #b5b5b5;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.pagination.custom-pagination .page-item + .page-item {
  padding-left: 0.5rem;
  margin: 0;
}
.pagination.custom-pagination .page-link {
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}
.pagination.custom-pagination.pagination-rounded .page-link {
  border-radius: 50rem;
}
.pagination.custom-pagination.pagination-rounded
  .page-item:first-child
  .page-link,
.pagination.custom-pagination.pagination-rounded
  .page-item:last-child
  .page-link {
  border-radius: 50rem;
}
.pagination.custom-pagination.pagination-filled
  .page-item:not(.active)
  .page-link {
  background: #f7f7f7;
  border-color: #f7f7f7;
}
.pagination.custom-pagination.pagination-filled
  .page-item:not(.active):hover
  .page-link {
  background-color: #eaeaea;
}
.pagination.custom-pagination.pagination-simple
  .page-item:not(.active)
  .page-link {
  border-color: transparent;
}
.pagination.pagination-sm .page-link {
  padding: 0.3rem 0.6rem;
  min-height: 30px;
  min-width: 30px;
}

/*Custom Modal*/

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.bar-chart {
  width: 100% !important;
  height: 100% !important;
}

.hover-icon {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: inline-block;
}
.hover-icon svg {
  transition: transform 0.5s ease-in-out;
}
.hover-icon:hover {
  color: #0c8ce9;
}
.hover-icon:hover svg {
  transform: scale(1.2);
}

.complaint-page-img {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@media (max-width: 768px) {
  .complaint-page-img {
    top: 0;
    left: auto;
    right: 0 !important;
    transform: translate(0, -50%);
  }
}

.dynamic-width-card {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  max-width: fit-content;
  width: auto !important;
  white-space: normal;
}

.complaint-flex-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
.footer-text-animation {
  animation: slideIn 20s linear infinite;
}

/* Print Table */
.print-table {
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
}

.print-table th,
.print-table td {
  border: 1px solid #dee2e6;
  vertical-align: text-top;
  padding: 8px;
}

.print-table td {
  font-size: 14px;
}

.print-table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}
